import React, { FunctionComponent, useState } from "react"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import OnScreen from "../components/OnScreen"
import Button from "../components/Button"
import TypedText from "../components/TypedText"
import ResultBar from "../components/ResultBar"

const QuizResults: FunctionComponent<PageProps> = ({ location }) => {
  const [showCore1, setShowCore1] = useState(false)
  const [showCore2, setShowCore2] = useState(false)
  const [showCore3, setShowCore3] = useState(false)
  const [showCore4, setShowCore4] = useState(false)
  const [showCore5, setShowCore5] = useState(false)

  const params = new URLSearchParams(location.search)

  const core1 = parseInt(params.get("core_1") || "-1", 10)
  const core2 = parseInt(params.get("core_2") || "-1", 10)
  const core3 = parseInt(params.get("core_3") || "-1", 10)
  const core4 = parseInt(params.get("core_4") || "-1", 10)
  const core5 = parseInt(params.get("core_5") || "-1", 10)

  return (
    <Layout>
      <SEO title="Core Evaluation Results" />

      <div className="bg-blue mt-4">
        <div className="container mx-auto px-4">
          <h1 className="font-Orbitron py-4 text-2xl text-white sm:text-3xl lg:py-8 lg:text-4xl">
            Your 5 Core Scores
          </h1>
        </div>
      </div>

      <div className="container mx-auto my-8 px-4 space-y-16">
        <h2 className="font-Orbitron text-2xl text-center sm:text-3xl lg:text-4xl">
          {`Total Happiness Score: ${core1 + core2 + core3 + core4 + core5}`}
        </h2>

        <div className="space-y-8">
          <div className="border-b-2 border-coral pb-4">
            <div className=" flex items-center justify-between">
              <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                Mindset
              </h2>

              <div className="flex items-center">
                <p className="font-Orbitron text-xl sm:mr-8 sm:text-2xl lg:text-3xl">
                  {`${core1}/6`}
                </p>

                <Button
                  className="hidden sm:block"
                  onClick={() => setShowCore1(true)}
                >
                  What does this mean?
                </Button>
              </div>
            </div>

            <Button
              className="mt-4 w-full sm:hidden"
              onClick={() => setShowCore1(true)}
            >
              What does this mean?
            </Button>
          </div>

          {showCore1 ? (
            <OnScreen
              className="bg-white p-4 rounded-md shadow-lg lg:p-8"
              classToAdd="slide-in-left"
            >
              <div className="flex flex-col items-center lg:flex-row">
                <div className="flex items-center mb-8 w-full sm:w-2/3 lg:block lg:mb-0 lg:mr-8 lg:w-auto">
                  <div className="p-2 relative w-48 lg:w-64">
                    <div className="absolute core-mindset-gradient inset-0 rounded-full shadow-lg spin" />

                    <div className="core-gradient relative rounded-full shadow-lg p-4 lg:p-8">
                      <StaticImage
                        alt="will moore 5 cores mindset"
                        placeholder="blurred"
                        src="../images/mindset-icon.png"
                      />
                    </div>
                  </div>

                  <div className="ml-12 relative w-full lg:ml-8 lg:mt-8 lg:w-auto">
                    <div className="absolute core-gradient -left-8 p-2 rounded-full top-1/2 transform -translate-y-1/2 z-10">
                      <StaticImage
                        alt="will moore 5 cores mindset"
                        className="w-8"
                        placeholder="blurred"
                        src="../images/mindset-icon.png"
                      />
                    </div>

                    <ResultBar score={core1} show={showCore1} />
                  </div>
                </div>

                <div className="flex-1 space-y-4">
                  <div className="border-b-2 border-coral flex items-center justify-between pb-2">
                    <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                      Mindset
                    </h2>

                    <p className="font-bold text-lg md:text-xl">
                      {`Your Score: ${core1}/6`}
                    </p>
                  </div>

                  <p>
                    Getting your mind working FOR instead of against you by
                    shaping both your perception of the world and your
                    confidence to achieve the goals you set within it.
                  </p>

                  <p>
                    We live in a world where it&apos;s become commonplace to
                    prioritize the wrong things, causing us to
                    &quot;miswant&quot; the wrong things that promote the{" "}
                    <strong>fixed victim mindset</strong> - convinced the world
                    is out to get us and there&apos;s nothing we can do about
                    it.
                  </p>

                  <p>
                    You&apos;ll want to instead develop a{" "}
                    <strong>growth owner mindset</strong> who fails beautifully,
                    treats obstacles as temporary roadblocks waiting for
                    solutions, and gives fear the finger on your way to
                    accomplish any goal you set for yourself.
                  </p>

                  <p>
                    <strong>Success Habit Ex&apos;s:</strong> 1) CBRP Method
                    (Catch/Breathe/Reflect/Pivot) 2) Act vs. Dwell, 3) Morning
                    Mantra
                  </p>
                </div>
              </div>
            </OnScreen>
          ) : null}

          <div className="border-b-2 border-coral pb-4">
            <div className="flex items-center justify-between">
              <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                Career &amp; Finances
              </h2>

              <div className="flex items-center">
                <p className="font-Orbitron text-xl sm:mr-8 sm:text-2xl lg:text-3xl">
                  {`${core2}/6`}
                </p>

                <Button
                  className="hidden sm:block"
                  onClick={() => setShowCore2(true)}
                >
                  What does this mean?
                </Button>
              </div>
            </div>

            <Button
              className="mt-4 w-full sm:hidden"
              onClick={() => setShowCore2(true)}
            >
              What does this mean?
            </Button>
          </div>

          {showCore2 ? (
            <OnScreen
              className="bg-white p-4 rounded-md shadow-lg lg:p-8"
              classToAdd="slide-in-left"
            >
              <div className="flex flex-col items-center lg:flex-row">
                <div className="flex items-center mb-8 w-full sm:w-2/3 lg:block lg:mb-0 lg:mr-8 lg:w-auto">
                  <div className="p-2 relative w-48 lg:w-64">
                    <div className="absolute core-finance-gradient inset-0 rounded-full shadow-lg spin" />

                    <div className="core-gradient relative rounded-full shadow-lg p-4 lg:p-8">
                      <StaticImage
                        alt="will moore 5 cores career finance"
                        placeholder="blurred"
                        src="../images/finance-icon.png"
                      />
                    </div>
                  </div>

                  <div className="ml-12 relative w-full lg:ml-8 lg:mt-8 lg:w-auto">
                    <div className="absolute core-gradient -left-8 p-2 rounded-full top-1/2 transform -translate-y-1/2 z-10">
                      <StaticImage
                        alt="will moore 5 cores career finance"
                        className="w-8"
                        placeholder="blurred"
                        src="../images/finance-icon.png"
                      />
                    </div>

                    <ResultBar score={core2} show={showCore2} />
                  </div>
                </div>

                <div className="flex-1 space-y-4">
                  <div className="border-b-2 border-coral flex items-center justify-between pb-2">
                    <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                      Career &amp; Finances
                    </h2>

                    <p className="font-bold text-lg md:text-xl">
                      {`Your Score: ${core2}/6`}
                    </p>
                  </div>

                  <p>
                    Most people have it backwards - buying into the outdated
                    myth that happiness comes from sacrificing your best years
                    so you can one day retire on a beach drinking Pina Coladas.
                    Truth bomb - they start tasting bitter after a week.
                  </p>

                  <p>
                    Real happiness springs from understanding WHY we&apos;re
                    here, then setting goals that combine our strengths and
                    passions to grow towards fulfilling that legacy every day.
                  </p>

                  <p>
                    Money is indeed a piece of the puzzle, and it&apos;s
                    important to learn to grow it exponentially, but you&apos;ll
                    first need to change your relationship with it to get it
                    working for YOU.
                  </p>

                  <p>
                    <strong>Success Habit Ex&apos;s:</strong> 1) Goal Setting
                    System 2) Screen-Time Limits 3) Auto-Investing Software
                  </p>
                </div>
              </div>
            </OnScreen>
          ) : null}

          <div className="border-b-2 border-coral pb-4">
            <div className="flex items-center justify-between">
              <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                Relationships
              </h2>

              <div className="flex items-center">
                <p className="font-Orbitron text-xl sm:mr-8 sm:text-2xl lg:text-3xl">
                  {`${core3}/6`}
                </p>

                <Button
                  className="hidden sm:block"
                  onClick={() => setShowCore3(true)}
                >
                  What does this mean?
                </Button>
              </div>
            </div>

            <Button
              className="mt-4 w-full sm:hidden"
              onClick={() => setShowCore3(true)}
            >
              What does this mean?
            </Button>
          </div>

          {showCore3 ? (
            <OnScreen
              className="bg-white p-4 rounded-md shadow-lg lg:p-8"
              classToAdd="slide-in-left"
            >
              <div className="flex flex-col items-center lg:flex-row">
                <div className="flex items-center mb-8 w-full sm:w-2/3 lg:block lg:mb-0 lg:mr-8 lg:w-auto">
                  <div className="p-2 relative w-48 lg:w-64">
                    <div className="absolute core-relationship-gradient inset-0 rounded-full shadow-lg spin" />

                    <div className="core-gradient relative rounded-full shadow-lg p-4 lg:p-8">
                      <StaticImage
                        alt="will moore 5 cores relationship"
                        placeholder="blurred"
                        src="../images/relationship-icon.png"
                      />
                    </div>
                  </div>

                  <div className="ml-12 relative w-full lg:ml-8 lg:mt-8 lg:w-auto">
                    <div className="absolute core-gradient -left-8 p-2 rounded-full top-1/2 transform -translate-y-1/2 z-10">
                      <StaticImage
                        alt="will moore 5 cores relationship"
                        className="w-8"
                        placeholder="blurred"
                        src="../images/relationship-icon.png"
                      />
                    </div>

                    <ResultBar score={core3} show={showCore3} />
                  </div>
                </div>

                <div className="flex-1 space-y-4">
                  <div className="border-b-2 border-coral flex items-center justify-between pb-2">
                    <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                      Relationships
                    </h2>

                    <p className="font-bold text-lg md:text-xl">
                      {`Your Score: ${core3}/6`}
                    </p>
                  </div>

                  <p>
                    Creating and maintaining deep, fulfilled relationships check
                    a lot of our happiness boxes. They create stability and
                    accountability in our lives, teach us empathy and give us
                    insight into ourselves, provide comfort when we&apos;re
                    distressed, and even contribute to our health.
                  </p>

                  <p>
                    Relationships also fill our basic need for companionship,
                    provide allies to help us fulfill our goals, and allow us to
                    experience the magic of love.
                  </p>

                  <p>
                    <strong>Success Habit Ex&apos;s:</strong> 1) Empathy 2)
                    Remember Names 3) Check Your Ego
                  </p>
                </div>
              </div>
            </OnScreen>
          ) : null}

          <div className="border-b-2 border-coral pb-4">
            <div className="flex items-center justify-between">
              <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                Physical Health
              </h2>

              <div className="flex items-center">
                <p className="font-Orbitron text-xl sm:mr-8 sm:text-2xl lg:text-3xl">
                  {`${core4}/6`}
                </p>

                <Button
                  className="hidden sm:block"
                  onClick={() => setShowCore4(true)}
                >
                  What does this mean?
                </Button>
              </div>
            </div>

            <Button
              className="mt-4 w-full sm:hidden"
              onClick={() => setShowCore4(true)}
            >
              What does this mean?
            </Button>
          </div>

          {showCore4 ? (
            <OnScreen
              className="bg-white p-4 rounded-md shadow-lg lg:p-8"
              classToAdd="slide-in-left"
            >
              <div className="flex flex-col items-center lg:flex-row">
                <div className="flex items-center mb-8 w-full sm:w-2/3 lg:block lg:mb-0 lg:mr-8 lg:w-auto">
                  <div className="p-2 relative w-48 lg:w-64">
                    <div className="absolute core-physical-health-gradient inset-0 rounded-full shadow-lg spin" />

                    <div className="core-gradient relative rounded-full shadow-lg p-4 lg:p-8">
                      <StaticImage
                        alt="will moore 5 cores physical health"
                        placeholder="blurred"
                        src="../images/physical-health-icon.png"
                      />
                    </div>
                  </div>

                  <div className="ml-12 relative w-full lg:ml-8 lg:mt-8 lg:w-auto">
                    <div className="absolute core-gradient -left-8 p-2 rounded-full top-1/2 transform -translate-y-1/2 z-10">
                      <StaticImage
                        alt="will moore 5 cores physical health"
                        className="w-8"
                        placeholder="blurred"
                        src="../images/physical-health-icon.png"
                      />
                    </div>

                    <ResultBar score={core4} show={showCore4} />
                  </div>
                </div>

                <div className="flex-1 space-y-4">
                  <div className="border-b-2 border-coral flex items-center justify-between pb-2">
                    <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                      Physical Health
                    </h2>

                    <p className="font-bold text-lg md:text-xl">
                      {`Your Score: ${core4}/6`}
                    </p>
                  </div>

                  <p>
                    We all know we should &quot;stay in shape,&quot; but few of
                    us understand what it truly means to take care of ourselves
                    physically, and the repercussions of ignoring it.
                  </p>

                  <p>
                    Science has proven the direct connection between the mind
                    and body, so to ignore aspects such as sleep, eating, and
                    exercise is to decrease your energy, stamina, and longevity
                    needed to thrive in your other cores.
                  </p>

                  <p>
                    <strong>Success Habit Ex&apos;s:</strong> 1) Get min 7 hours
                    sleep 2) Combine what you love to do with your workouts 3)
                    Track your fitness with a wearable
                  </p>
                </div>
              </div>
            </OnScreen>
          ) : null}

          <div className="border-b-2 border-coral pb-4">
            <div className="flex items-center justify-between">
              <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                Emotional Health
              </h2>

              <div className="flex items-center">
                <p className="font-Orbitron text-xl sm:mr-8 sm:text-2xl lg:text-3xl">
                  {`${core5}/6`}
                </p>

                <Button
                  className="hidden sm:block"
                  onClick={() => setShowCore5(true)}
                >
                  What does this mean?
                </Button>
              </div>
            </div>

            <Button
              className="mt-4 w-full sm:hidden"
              onClick={() => setShowCore5(true)}
            >
              What does this mean?
            </Button>
          </div>

          {showCore5 ? (
            <OnScreen
              className="bg-white p-4 rounded-md shadow-lg lg:p-8"
              classToAdd="slide-in-left"
            >
              <div className="flex flex-col items-center lg:flex-row">
                <div className="flex items-center mb-8 w-full sm:w-2/3 lg:block lg:mb-0 lg:mr-8 lg:w-auto">
                  <div className="p-2 relative w-48 lg:w-64">
                    <div className="absolute core-mental-health-gradient inset-0 rounded-full shadow-lg spin" />

                    <div className="core-gradient relative rounded-full shadow-lg p-4 lg:p-8">
                      <StaticImage
                        alt="will moore 5 cores emotional health"
                        placeholder="blurred"
                        src="../images/emotional-health-icon.png"
                      />
                    </div>
                  </div>

                  <div className="ml-12 relative w-full lg:ml-8 lg:mt-8 lg:w-auto">
                    <div className="absolute core-gradient -left-8 p-2 rounded-full top-1/2 transform -translate-y-1/2 z-10">
                      <StaticImage
                        alt="will moore 5 cores emotional health"
                        className="w-8"
                        placeholder="blurred"
                        src="../images/emotional-health-icon.png"
                      />
                    </div>

                    <ResultBar score={core5} show={showCore5} />
                  </div>
                </div>

                <div className="flex-1 space-y-4">
                  <div className="border-b-2 border-coral flex items-center justify-between pb-2">
                    <h2 className="font-Orbitron text-xl sm:text-2xl lg:text-3xl">
                      Emotional Health
                    </h2>

                    <p className="font-bold text-lg md:text-xl">
                      {`Your Score: ${core5}/6`}
                    </p>
                  </div>

                  <p>
                    Ferris Bueller said &quot;Life moves pretty fast. If you
                    don&apos;t stop and look around once in a while, you could
                    miss it.&quot; Your EH is about expressing your passions
                    regularly, regulating your emotions and managing your
                    stress, and figuring out a way to become teammates with the
                    world, not enemies.
                  </p>

                  <p>
                    One of the most important aspects of this? - ensuring the
                    world is better, not worse, for having you in it.
                  </p>

                  <p>
                    <strong>Success Habit Ex&apos;s:</strong> 1) Mediate 2)
                    Proactively schedule what you love 3) Gratefulness routine
                  </p>
                </div>
              </div>
            </OnScreen>
          ) : null}
        </div>

        <div className="bg-blue p-8 rounded-md shadow-lg text-center text-white">
          <TypedText
            className="font-Press-Start mb-4 px-4 text-center text-lg"
            text="Ready to fire on all cylinders in all 5 Cores?"
          />

          <div className="my-8 space-y-4 xl:max-w-4xl text-left">
            <p className="text-xl">
              Ready to fire on all cylinders in all 5 Cores? Download our FREE
              Ebook to keep your momentum going.
            </p>
          </div>

          <a className="btns w-max m-auto" href="/momentum-guide.pdf" download>
            Free Ebook Download
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default QuizResults
